import logo from './logo.svg';
import './ViewCartButton.css';
import React, { useState, useContext } from "react";

function ViewCartButton({ NumberOfItems, openModalOrderConfirmation, widthClass, useCase, toggleItemAvailabilityModal, returnToAdminScreen }) {
  return (
        <ViewCartButtonBody NumberOfItems={NumberOfItems} openModalOrderConfirmation={openModalOrderConfirmation} widthClass={widthClass} useCase={useCase} toggleItemAvailabilityModal={toggleItemAvailabilityModal} returnToAdminScreen={returnToAdminScreen} />
  );
}

export default ViewCartButton;

function ViewCartButtonBody({ NumberOfItems, openModalOrderConfirmation, widthClass, useCase, toggleItemAvailabilityModal, returnToAdminScreen }){
	if (useCase === 'restaurant-facing-app-kitchen' || useCase === 'restaurant-facing-app-admin') {
		return (
			<button className={`view-cart-button-${widthClass}-${useCase}`} onClick={useCase === 'restaurant-facing-app-kitchen' ? toggleItemAvailabilityModal : returnToAdminScreen}>
				<div className="view-cart-span">Save</div>
			</button>
		)	
	} else {
		let buttonClass = useCase !== undefined ? `view-cart-button-${widthClass}-${useCase}` : `view-cart-button-${widthClass}`;
		return (
			<button className={buttonClass} onClick={openModalOrderConfirmation}>
				<div className="view-cart-span">View Cart <span> ({ NumberOfItems })</span></div>
			</button>
		)
	}
}