// <style type="text/css">
	
// .carousel-body {
// 	overflow-y: hidden;
// 	white-space: nowrap;
// }

// .image-card {
// 	height: 172px;
// 	width: 400px;
// 	/* Center the overflowing image */
//     position: relative;
//     /* End center the overflowing image */
// 	overflow: hidden;
// 	white-space: nowrap;
// 	display: inline-block;
// }

// .image {
// 	height: 225px;
// 	width: 400px;
// 	/* Center the overflowing image */
//     position: absolute;
//     top: -9999px;
//     bottom: -9999px;
//     left: -9999px;
//     right: -9999px;
//     margin: auto;
//     /* End center the overflowing image */
// }

// .card {
// 	width: 400px;
// }

// .info-card {
// 	margin-top: 12px;
// 	/*clear: both;*/
// 	display: inline-block;
// 	width: 400px;
// }

// .restaurant-name {
// 	font-family: Roboto;
// 	font-weight: 700;
// }

// .restaurant-other-info {
// 	display: inline-block;
// 	color: grey;
// }

// </style>

import React, { useState, useContext } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// function carouselBody(props){
	
// }

// function imageCardContainer(props){
	
// }

// function imageCard(props){
	
// }

//function image(props){
//	return (
//		<img class='image' src="{props.imageSrc}">
//	)
//}

// function infoCardContainer(props){
	
// }

// function textCard(props){
	
// }

// function restaurantName(props){
	
// }

// function restaurantOtherInfo(props){
	
// }

// <div class='carousel-body'>
// 	<div class='image-card-container'>

// 		<a href='https://google.com' class='image-card'>
// 			<img class='image' src="https://img.cdn4dd.com/p/fit=cover,width=400,format=auto,quality=50/media/store/header/5579.jpg">
// 		</a>

// 		<a href='https://google.com' class='image-card'>
// 			<img class='image' src="https://img.cdn4dd.com/p/fit=cover,width=400,format=auto,quality=50/media/photosV2/0e3f37b0-31a1-45bf-bc5a-dfad75a0ca13-retina-large.JPG">
// 		</a>

// 		<a href='https://google.com' class='image-card'>
// 			<img class='image' src="https://img.cdn4dd.com/p/fit=cover,width=400,format=auto,quality=50/media/store/header/5579.jpg">
// 		</a>

// 		<a href='https://google.com' class='image-card'>
// 			<img class='image' src="https://img.cdn4dd.com/p/fit=cover,width=400,format=auto,quality=50/media/photosV2/0e3f37b0-31a1-45bf-bc5a-dfad75a0ca13-retina-large.JPG">
// 		</a>

// 		<a href='https://google.com' class='image-card'>
// 			<img class='image' src="https://img.cdn4dd.com/p/fit=cover,width=400,format=auto,quality=50/media/store/header/5579.jpg">
// 		</a>

// 		<a href='https://google.com' class='image-card'>
// 			<img class='image' src="https://img.cdn4dd.com/p/fit=cover,width=400,format=auto,quality=50/media/photosV2/0e3f37b0-31a1-45bf-bc5a-dfad75a0ca13-retina-large.JPG">
// 		</a>

// 	</div>

// 	<div class="info-card-container">

// 		<div class="info-card">
// 			<div class='restaurant-name'>
// 				McDonald's
// 			</div>
// 			<div class='restaurant-other-info'>
// 				7.9 mi • 51 min
// 			</div>
// 		</div>

// 		<div class="info-card">
// 			<div class='restaurant-name'>
// 				Taco Bell
// 			</div>
// 			<div class='restaurant-other-info'>
// 				5.6 mi • 42 min
// 			</div>
// 		</div>

// 		<div class="info-card">
// 			<div class='restaurant-name'>
// 				McDonald's
// 			</div>
// 			<div class='restaurant-other-info'>
// 				7.9 mi • 51 min
// 			</div>
// 		</div>

// 		<div class="info-card">
// 			<div class='restaurant-name'>
// 				Taco Bell
// 			</div>
// 			<div class='restaurant-other-info'>
// 				5.6 mi • 42 min
// 			</div>
// 		</div>

// 		<div class="info-card">
// 			<div class='restaurant-name'>
// 				McDonald's
// 			</div>
// 			<div class='restaurant-other-info'>
// 				7.9 mi • 51 min
// 			</div>
// 		</div>

// 		<div class="info-card">
// 			<div class='restaurant-name'>
// 				Taco Bell
// 			</div>
// 			<div class='restaurant-other-info'>
// 				5.6 mi • 42 min
// 			</div>
// 		</div>

// 	</div>

// </div>
