import logo from './logo.svg';
import './RestaurantImage.css';
import React, { useState, useContext } from "react";


function RestaurantImage({ restaurantImageSrc, useCase }) {
  return (
        <RestaurantImageBody restaurantImageSrc={restaurantImageSrc} useCase={useCase} />
  );
}

export default RestaurantImage;

function RestaurantImageBody({ restaurantImageSrc, useCase }){
	return (
		<div className='restaurant-image-container'>
		    <img className={useCase === 'user-facing-app' ? 'restaurant-image-MenuApp-user-facing-app' : 'restaurant-image'} src={restaurantImageSrc}/>
	    </div>
	)
}