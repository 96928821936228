import logo from './logo.svg';
import './ModalOptions.css';
import { formatDollars } from './utils.js';
import AddToOrderButton from './AddToOrderButton';
import React, { useState, useContext } from 'react';

function ModalOptions({ closeModalOptions, modalOptionsData, widthClass, orderData, useCase }) {
    const optionSections = modalOptionsData['options'].map((data, index) => (
        <OptionSection
            key={index}
            orderData={orderData}
            optionSectionData={data}
            checkInputAndUpdateOrder={checkInputAndUpdateOrder}
            useCase={useCase}
        />
    ));
    const [price, setPrice] = useState([modalOptionsData['price'] == '' ? 0 : modalOptionsData['price']]);
    const [quantity, setQuantity] = useState(1);
    let itemData = {};
    itemData['id'] = orderData['items'].length;
    itemData['itemId'] = modalOptionsData['id'];
    itemData['title'] = modalOptionsData['title'];
    let itemPrice = modalOptionsData['price'] == '' ? 0 : parseFloat(modalOptionsData['price']);
    itemData['price'] = itemPrice;
    function updateItem(orderData) {
        itemData['price'] = itemPrice;
        let options = [];
        let optionSections = [...document.getElementsByClassName('modalOptions-options-section-body')];
        optionSections.forEach(function (optionSection) {
            let optionTitle = optionSection.getElementsByClassName('modalOptions-options-section-title')[0].textContent;
            let optionValue = [];
            let optionInputs = [...optionSection.getElementsByClassName('modalOptions-option-body')];
            optionInputs.forEach(function (optionInput) {
                let optionInputInput = optionInput.getElementsByTagName('input')[0];
                if (optionInputInput.checked) {
                    let optionValueTitle = optionInput.getElementsByClassName('modalOptions-option-title')[0].textContent;
                    let optionValueCost = optionInput.getElementsByClassName('modalOptions-option-cost')[0].textContent;
                    console.log(optionValueCost)
                    optionValueCost =
                        optionValueCost == undefined || optionValueCost == ''
                            ? 0
                            : parseFloat(optionValueCost.replace("$", ""));
                    optionValue.push({
                        title: optionValueTitle,
                        cost: optionValueCost,
                    });
                    itemData['price'] = roundDollar(itemData['price'] + optionValueCost);
                }
            });
            options.push({
                title: optionTitle,
                value: optionValue,
            });
        });
        itemData['options'] = options;
        itemData['specialInstructions'] = document.getElementsByClassName(
            'modalOptions-special-instructions-input',
        )[0].value;
        let quantity = parseInt(document.getElementsByClassName('modalOptions-quantityButton-quantity')[0].textContent);
        itemData['quantity'] = quantity;
        setPrice(itemData['price']);
    }

    function checkInput(input, checkBool) {
        let costElements = input.parentNode.getElementsByClassName('modalOptions-option-cost');
        if (checkBool) {
            if (costElements.length > 0) {
                costElements[0].classList.add('modalOptions-option-cost-active');
            }
            input.checked = true;
        } else {
            if (costElements.length > 0) {
                costElements[0].classList.remove('modalOptions-option-cost-active');
            }
            input.checked = false;
        }
    }

    function checkInputAndUpdateOrder(event, orderData, optionSectionTitle, optionSectionLimit) {
        console.log("checkInputAndUpdateOrder")
        let target = event.target;
        if (target.tagName != 'INPUT') {
            if (target.className != 'modalOptions-option-body') {
                target = target.parentNode;
            }
            let input = target.getElementsByTagName('input')[0];
            var checkedChecks = document.querySelectorAll("input[name='" + optionSectionTitle + "']:checked");
            if (checkedChecks.length < optionSectionLimit) {
                // input.checked = input.checked ? false : true;
                checkInput(input, input.checked ? false : true);
            } else if (optionSectionLimit == 1) {
                if (checkedChecks.length == 0) {
                    checkInput(input, true);
                } else {
                    document
                        .querySelectorAll("input[name='" + optionSectionTitle + "']")
                        .forEach((el) => (el.checked = false));
                    checkInput(input, true);
                }
            } else {
                checkInput(input, false);
            }
        }
        updateItem(orderData);
    }

    function cloneData(data) {
        return JSON.parse(JSON.stringify(data));
    }

    function updateOrder(orderData) {
        updateItem(orderData);
        orderData['numberOfItems'] += parseInt(itemData['quantity']);
        orderData['items'].push(itemData);
        closeModalOptions();
    }

    return (
        <>
            <div onClick={closeModalOptions} className="overlay"></div>
            <div className={`modalOptions-${widthClass}`}>
                <button onClick={closeModalOptions} className="close-button">
                    &times;
                </button>
                <header className={`modalOptions-header-${widthClass}`}>
                    <ImageContainer modalOptionsData={modalOptionsData} />
                </header>
                <main className="modalOptions-main">
                    { (useCase !== 'restaurant-facing-app-kitchen' && useCase !== 'restaurant-facing-app-admin') && 
                        <h2 className="modalOptions-title">{modalOptionsData['title']}</h2>
                    }
                    { (useCase === 'restaurant-facing-app-kitchen' || useCase === 'restaurant-facing-app-admin') && 
                        <input className="modalOptions-title-input modalOptions-input" defaultValue={modalOptionsData['title']} />
                    }
                    { (useCase !== 'restaurant-facing-app-kitchen' && useCase !== 'restaurant-facing-app-admin') && 
                        <p className="modalOptions-price">
                            ${formatDollars(modalOptionsData['price'] == '' ? 0 : modalOptionsData['price'])}
                        </p>
                    }
                    { (useCase === 'restaurant-facing-app-kitchen' || useCase === 'restaurant-facing-app-admin') && 
                        <input className="modalOptions-price-input modalOptions-input" defaultValue={"$" + formatDollars(modalOptionsData['price'] == '' ? 0 : modalOptionsData['price'])} />
                    }
                    { useCase === 'restaurant-facing-app-admin' && 
                        <textarea rows={5} className="modalOptions-description-input modalOptions-input" defaultValue={modalOptionsData['description']}></textarea>
                    }
                    { useCase !== 'restaurant-facing-app-admin' && 
                        <p className="modalOptions-description">{modalOptionsData['description']}</p>
                    }
                    <p className="options-disclaimer">
                        * Because of the difficulty of scraping dish options from DoorDash, all dishes have been temporarily hardcoded.
                    </p>
                    {optionSections}
                    { (useCase !== 'restaurant-facing-app-kitchen' && useCase !== 'restaurant-facing-app-admin') && 
                        <>
                            <SpecialInstructions />
                            <QuantityButton quantity={quantity} setQuantity={setQuantity} />
                        </>
                    }
                    { (useCase === 'restaurant-facing-app-kitchen' || useCase === 'restaurant-facing-app-admin') && 
                        <div className={`modalOptions-spacer-${useCase}`}></div>
                    }
                    { (useCase === undefined || useCase === 'user-facing-app') && 
                        <AddToOrderButton
                            widthClass={widthClass}
                            onClickFunction={() => updateOrder(orderData)}
                            price={formatDollars(price * quantity)}
                            useCase={useCase}
                        />
                    }
                    { (useCase !== undefined && useCase !== 'user-facing-app') && 
                        <AddToOrderButton
                            widthClass={widthClass}
                            onClickFunction={() => closeModalOptions()}
                            price={formatDollars(price * quantity)}
                            useCase={useCase}
                        />
                    }
                </main>
            </div>
        </>
    );
}

function ImageContainer({ modalOptionsData }) {
    if (modalOptionsData['image'] == '') {
        return <div className="modalOptions-image-container-empty"></div>;
    } else {
        return (
            <div className="modalOptions-image-container">
                <img className="modalOptions-image" src={modalOptionsData['image']}></img>
            </div>
        );
    }
}

function OptionSection({ orderData, optionSectionData, checkInputAndUpdateOrder, useCase }) {
    let optionSectionLimit = optionSectionData['limit'];
    let optionType = optionSectionData['optionType'];
    let optionSectionTitle = optionSectionData['title'];
    const options = optionSectionData['options'].map((data, index) => (
        <Option
            key={index}
            orderData={orderData}
            optionData={data}
            optionType={optionType}
            optionSectionTitle={optionSectionTitle}
            optionSectionLimit={optionSectionLimit}
            checkInputAndUpdateOrder={checkInputAndUpdateOrder}
            useCase={useCase}
        />
    ));
    return (
        <div class="modalOptions-options-section-body">
            { (useCase !== 'restaurant-facing-app-kitchen' && useCase !== 'restaurant-facing-app-admin') && 
                <p className="modalOptions-options-section-title">{optionSectionData['title']}</p>
            }
            { (useCase === 'restaurant-facing-app-kitchen' || useCase === 'restaurant-facing-app-admin') && 
                <>
                    <input className="modalOptions-options-section-title-input modalOptions-input" defaultValue={optionSectionData['title']} />
                    <div className="modalOptions-options-section-title-input-position-button-container">
                        <button className="modalOptions-options-section-title-input-position-button">
                            <svg width="12" height="8" viewBox="0 0 6 4" fill="none">
                                <path d="M5.5957 3.40267H0.404295C0.0448247 3.40267 -0.135193 2.96805 0.118986 2.71387L2.71469 0.118173C2.87225 -0.0393908 3.12774 -0.0393908 3.2853 0.118173L5.881 2.71387C6.1352 2.96805 5.95517 3.40267 5.5957 3.40267Z" fill="#838B93"/>
                            </svg>
                        </button>
                        <button className="modalOptions-options-section-title-input-position-button">
                            <svg width="12" height="8" viewBox="0 0 6 4" fill="none">
                                <path d="M5.5957 0.000158787H0.404295C0.0448247 0.000158787 -0.135193 0.434779 0.118986 0.688959L2.71469 3.28466C2.87225 3.44222 3.12774 3.44222 3.2853 3.28466L5.881 0.688959C6.1352 0.434779 5.95517 0.000158787 5.5957 0.000158787Z" fill="#838B93"/>
                            </svg>
                        </button>
                    </div>
                </>
            }
            <OptionSectionLimitString optionType={optionType} limit={optionSectionLimit} useCase={useCase} />
            {options}
        </div>
    );
}

function OptionSectionLimitString({ optionType, limit, useCase }) {
    if (useCase !== 'restaurant-facing-app-kitchen' && useCase !== 'restaurant-facing-app-admin'){
        if (limit > 1) {
            return <p className="modalOptions-options-section-limit-string">Select up to {limit}</p>;
        } else if (limit == 1 || optionType == 'selectOne') {
            return <p className="modalOptions-options-section-limit-string">Select one</p>;
        } else {
            return <></>;
        }
    } 
    else {
        return (
            <div className="modalOptions-option-section-limit-container">
                <input className="modalOptions-option-section-min-input modalOptions-input" defaultValue={0} maxlength={2} /> to 
                <input className="modalOptions-option-section-max-input modalOptions-input" defaultValue={1} maxlength={2} /> selections
            </div>
        )
    }
}

function Option({ orderData, optionData, optionType, optionSectionTitle, optionSectionLimit, checkInputAndUpdateOrder, useCase }) {
    let cost = optionData['cost'];
    let costString = cost === 0 ? '' : '+$' + formatDollars(cost);
    // let costString = cost === 0 ? "" : "$" + formatter.format(cost);
    // var formatter = new Intl.NumberFormat('en-US', {
    //   style: 'currency',
    //   currency: 'USD',

    // // These options are needed to round to whole numbers if that's what you want.
    // //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    // });
    return (
        <div
            className={ useCase === undefined ? "modalOptions-option-body" : `modalOptions-option-body modalOptions-option-body-${useCase}` }
            onClick={(e) => checkInputAndUpdateOrder(e, orderData, optionSectionTitle, optionSectionLimit)}
        >
            { useCase === 'restaurant-facing-app-admin' &&
                <svg className="modalOptions-option-position-icon" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M12.55 2.9999C12.6605 2.9999 12.75 2.91036 12.75 2.7999V1.5509C12.7499 1.47958 12.7701 1.40971 12.8083 1.34948C12.8465 1.28925 12.9011 1.24116 12.9656 1.21085C13.0302 1.18053 13.102 1.16926 13.1728 1.17835C13.2435 1.18744 13.3102 1.21651 13.365 1.26215L16.455 3.8369C16.5142 3.88628 16.5568 3.95272 16.5769 4.02716C16.597 4.1016 16.5936 4.18042 16.5673 4.2529C16.541 4.32537 16.493 4.38797 16.4298 4.43217C16.3666 4.47637 16.2914 4.50002 16.2142 4.4999H1.7C1.58954 4.4999 1.5 4.41036 1.5 4.2999V3.1999C1.5 3.08945 1.58954 2.9999 1.7 2.9999H12.55ZM1.5 13.6999C1.5 13.5894 1.58954 13.4999 1.7 13.4999H16.3C16.4105 13.4999 16.5 13.5894 16.5 13.6999V14.7999C16.5 14.9104 16.4105 14.9999 16.3 14.9999H1.7C1.58954 14.9999 1.5 14.9104 1.5 14.7999V13.6999ZM1.5 8.4499C1.5 8.33945 1.58954 8.2499 1.7 8.2499H16.3C16.4105 8.2499 16.5 8.33945 16.5 8.4499V9.5499C16.5 9.66036 16.4105 9.7499 16.3 9.7499H1.7C1.58954 9.7499 1.5 9.66036 1.5 9.5499V8.4499Z" fill="#838B93"/>
                </svg>
            }
            { useCase !== 'restaurant-facing-app-admin' &&
                <input
                    type={optionType == 'selectOne' ? 'radio' : 'checkbox'}
                    name={optionSectionTitle}
                    className="modalOptions-option-input"
                    onClick={(e) => limitCheckboxClicks(e, optionSectionTitle, optionSectionLimit)}
                />
            }
            { useCase === 'restaurant-facing-app-admin' &&
                <>
                    <input className="modalOptions-option-title-input modalOptions-input" defaultValue={optionData['title']} />
                    <input className="modalOptions-option-cost-input modalOptions-input" defaultValue={costString} />
                </>
            }
            { useCase !== 'restaurant-facing-app-admin' &&
                <>
                    <span className="modalOptions-option-title">{optionData['title']}</span>
                    <span className="modalOptions-option-cost">{costString}</span>
                </>
            }
        </div>
    );
}

function limitCheckboxClicks(e, optionSectionTitle, limit) {
    console.log("limitCheckboxClicks")
    var checkedChecks = document.querySelectorAll("input[name='" + optionSectionTitle + "']:checked");
    if (checkedChecks.length >= limit + 1) {
        e.preventDefault();
    }
}

function SpecialInstructions() {
    return (
        <div>
            <p className="modalOptions-special-instructions-label">Special Instructions</p>
            <textarea
                name="modalOptions-special-instructions-input"
                className="modalOptions-special-instructions-input"
                type="text"
            ></textarea>
        </div>
    );
}

function QuantityButton({ quantity, setQuantity }) {
    return (
        <div className="modalOptions-quantityButton-container">
            <div className="modalOptions-quantityButton">
                <button
                    className="modalOptions-quantityButton-btn"
                    onClick={() => (quantity > 1 ? setQuantity(quantity - 1) : null)}
                >
                    &minus;
                </button>
                <p className="modalOptions-quantityButton-quantity">{quantity}</p>
                <button className="modalOptions-quantityButton-btn" onClick={() => setQuantity(quantity + 1)}>
                    &#43;
                </button>
            </div>
        </div>
    );
}

function roundDollar(num) {
    return Math.round(num * 100) / 100;
}

export default ModalOptions;
