import './App.css';
import SearchBar from './SearchBar';
import RestaurantImage from './RestaurantImage';
import RestaurantProfileInfo from './RestaurantProfileInfo';
import Menu from './Menu';
import MenuSectionScroller from './MenuSectionScroller';
import ViewCartButton from './ViewCartButton';
import ModalOptions from './ModalOptions';
import ModalOrderConfirmation from './ModalOrderConfirmation';
import ModalCheckout from './ModalCheckout';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { APIURL } from './constants';

function MenuApp({ useCase, toggleItemAvailabilityModal, returnToAdminScreen }) {
    let widthClass = window.innerWidth > 740 ? 'wide' : 'narrow';
    // let RestaurantProfileInfoDataJSON = {
    //     name: 'Taco Bell',
    //     hours: 'Closes at 11:30 PM',
    //     distance: '4.5 mi',
    // };
    let RestaurantProfileInfoDataJSON = {
        name: "Mo' Bettahs Hawaiian Style",
        hours: 'Closes at 10:00 PM',
        distance: '4.5 mi',
    };
    // let location = "taco_bell";
    let location = 'mo_bettahs';
    let locationImageDict = {
        taco_bell:
            'https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/f4382bb2-c3de-4c33-bb65-fa144e999906.jpg',
        mo_bettahs:
            'https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/40bea08a-cf1f-4539-b1eb-5fab9b557f7f.jpg',
    };
    const [MenuDataOriginal, setMenuDataOriginal] = useState([]);
    const [MenuData, setMenuData] = useState([]);
    const [MenuDataIndexById, setMenuDataIndexById] = useState({});
    const [hasSearchQuery, setHasSearchQuery] = useState(false);
    const [appClass, setAppClass] = useState('body');
    const [orderData, setOrderData] = useState({ numberOfItems: 0, items: [] });
    const [isOpenModalOptions, setIsOpenModalOptions] = useState(false);
    const [modalOptionsData, setModalOptionsData] = useState({});
    const [isSticky, setIsSticky] = useState(false);

    const [isOpenModalCheckout, setIsOpenModalCheckout] = useState(false);

    const load = async () => {
        let url = new URL(APIURL + '/get_menu_data');
        // let params = {"location": "taco_bell"}
        let params = { location: location };
        url.search = new URLSearchParams(params).toString();
        let options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const data = await fetch(url, options).then((res) => res.json());

        const itemsById = getMenuDataIndexById(data);
        setMenuDataIndexById(itemsById);
        setMenuDataOriginal(data);
        setMenuData(data);
    };

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', onSticky);
        return () => {
            window.removeEventListener('scroll', onSticky);
        };
    }, []);

    useEffect(() => {
        if(isSticky){
            const w = document.querySelector('.rest-of-body').offsetWidth;
            // console.log('width:', w)
            var stickyHeader = document.querySelector('.sticky-header-fixed-' + widthClass);
            // console.log("sticky-header:", stickyHeader)
            var stickerHeaderWidth = String(w * (1 / 0.99) + 1) + 'px';
            // console.log("stickerHeaderWidth", stickerHeaderWidth)
            stickyHeader.style.width = stickerHeaderWidth;
        }
    }, [isSticky]);

    const onSticky = () => {
        const scrollTop = window.scrollY;
        var restaurantImageContainer = document.querySelector('.restaurant-image-container');
        const h1 = restaurantImageContainer == null ? 0 : restaurantImageContainer.offsetHeight;
        var restaurantProfileInfo = document.querySelector('.restaurant-profile-info-' + widthClass);
        const h2 = restaurantProfileInfo == null ? 0 : restaurantProfileInfo.offsetHeight;
        const h = h1 + h2 + 80;
        const isSticky = scrollTop >= h;
        setIsSticky(isSticky);
    };

    useEffect(() => {
        let sectionLabelATagsObserver = new MutationObserver((mutations) => {
            for (let mutation of mutations) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    let node = mutation.target;
                    if (node.className === 'active') {
                        document
                            .getElementsByClassName('menu-section-scroller-body-' + widthClass)[0]
                            .scroll({ left: node.offsetLeft });
                    }
                }
            }
        });
        var sectionLabelATags = [];
        [...document.getElementsByClassName('item-select')].forEach((el) => {
            let aTag = el.getElementsByTagName('a')[0];
            sectionLabelATags.push(aTag);
            sectionLabelATagsObserver.observe(aTag, { attributes: true });
        });
    }, [MenuData]);

    function searchMenuHelper(query) {
        const filteredMenuData = MenuDataOriginal.map((section) => {
            section.sectionItems = section.sectionItems.filter(
                (item) => item.title?.toLowerCase().includes(query) || item.description?.toLowerCase().includes(query),
            );
            return section;
        }).filter((section) => !!section.sectionItems.length);
        setMenuData(filteredMenuData);
    }

    function getMenuDataIndexById(data) {
        let MenuDataIndexById = {};
        data.forEach(function (section) {
            section['sectionItems'].forEach(function (item) {
                MenuDataIndexById[item['id']] = item;
            });
        });
        return MenuDataIndexById;
    }

    const openModalOptions = (modalOptionsData) => {
        setModalOptionsData(modalOptionsData);
        setIsOpenModalOptions(true);
        setAppClass('body-modal-open');
    };

    const closeModalOptions = () => {
        setIsOpenModalOptions(false);
        setAppClass('body');
    };

    const [isOpenModalOrderConfirmation, setIsOpenModalOrderConfirmation] = useState(false);
    const [modalOrderConfirmationData, setModalOrderConfirmationData] = useState({});
    const openModalOrderConfirmation = () => {
        setIsOpenModalOrderConfirmation(true);
        setAppClass('body-modal-open');
        getSubtotal(orderData);
    };

    const closeModalOrderConfirmation = () => {
        setIsOpenModalOrderConfirmation(false);
        setAppClass('body');
    };

    const openModalCheckout = () => {
        setIsOpenModalCheckout(true);
        setAppClass('body-modal-open');
    };

    const closeModalCheckout = () => {
        setIsOpenModalCheckout(false);
        setAppClass('body');
    };

    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [total, setTotal] = useState(0);
    function getSubtotal(orderData) {
        let items = orderData['items'];
        let subtotalTmp = 0.0;
        for (var idx = 0; idx <= items.length - 1; idx++) {
            subtotalTmp += items[idx]['price'] * items[idx]['quantity'];
        }
        let taxRate = 0.08;
        let taxTmp = taxRate * subtotalTmp;
        let totalTmp = subtotalTmp + taxTmp;
        setSubtotal(subtotalTmp);
        setTax(taxTmp);
        setTotal(totalTmp);
        // console.log('got subtotal');
    }

    return (
        <div className="menu-app">
            <div className={appClass}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="theme-color" content="#ec7211" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                    <title>Self-serve Kiosk</title>
                    <meta
                        name="viewport"
                        content="viewport-fit=cover, width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    ></meta>
                </Helmet>

                { (useCase !== 'restaurant-facing-app-kitchen' && useCase !== 'restaurant-facing-app-admin') &&
                    <RestaurantImage restaurantImageSrc={locationImageDict[location]} useCase={useCase} />
                }

                <div className="rest-of-body">
                    { (useCase !== 'restaurant-facing-app-kitchen' && useCase !== 'restaurant-facing-app-admin') &&
                        <RestaurantProfileInfo
                            RestaurantProfileInfoDataJSON={RestaurantProfileInfoDataJSON}
                            widthClass={widthClass}
                        />
                    }
                    <div className={isSticky ? 'sticky-header sticky-header-fixed-' + widthClass : 'sticky-header'}>
                        <SearchBar
                            searchMenuHelper={searchMenuHelper}
                            load={load}
                            hasSearchQuery={hasSearchQuery}
                            setHasSearchQuery={setHasSearchQuery}
                            useCase={useCase}
                        />
                        <MenuSectionScroller MenuData={MenuData} widthClass={widthClass} />
                    </div>
                    {isSticky && (
                        <div className={'sticky-header-placeholder-' + widthClass}>
                        </div>
                    )}
                    <div className="overflow-unset">
                        <Menu MenuData={MenuData} widthClass={widthClass} openModalOptions={openModalOptions} useCase={useCase} />
                        <ViewCartButton
                            NumberOfItems={orderData['numberOfItems']}
                            openModalOrderConfirmation={openModalOrderConfirmation}
                            widthClass={widthClass}
                            useCase={useCase}
                            toggleItemAvailabilityModal={toggleItemAvailabilityModal}
                            returnToAdminScreen={returnToAdminScreen}
                        />
                        {isOpenModalOptions && (
                            <ModalOptions
                                closeModalOptions={closeModalOptions}
                                modalOptionsData={modalOptionsData}
                                widthClass={widthClass}
                                orderData={orderData}
                                useCase={useCase}
                            />
                        )}

                        {isOpenModalOrderConfirmation && (
                            <ModalOrderConfirmation
                                closeModalOrderConfirmation={closeModalOrderConfirmation}
                                modalOrderConfirmationData={modalOrderConfirmationData}
                                orderData={orderData}
                                setOrderData={setOrderData}
                                MenuDataIndexById={MenuDataIndexById}
                                openModalCheckout={openModalCheckout}
                                subtotal={subtotal}
                                tax={tax}
                                total={total}
                                getSubtotal={getSubtotal}
                                widthClass={widthClass}
                            />
                        )}

                        {isOpenModalCheckout && (
                            <ModalCheckout
                                closeModalCheckout={closeModalCheckout}
                                openModalOrderConfirmation={openModalOrderConfirmation}
                                orderData={orderData}
                                setOrderData={setOrderData}
                                widthClass={widthClass}
                                load={load}
                                hasSearchQuery={hasSearchQuery}
                                setHasSearchQuery={setHasSearchQuery}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MenuApp;
